<template>
  <div class="card-body">
    <div v-if="getData.placement_type == 'EX'">
      <div v-if="getData.expenditure_description !== null">
        <div class="main__header" style="flex-wrap: wrap">
          <div class="col-4 pl-0">
            <div class="form-group">
              <label>Registratsiya raqami</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.reg_number"
                  disabled
              />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>Sana</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.oper_date | formatDate"
                  disabled
              />
            </div>
          </div>

          <div class="col-4 pr-0">
            <div class="form-group">
              <label>Qayerga</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  value="Xarajatlarga olingan"
                  disabled
              />
            </div>
          </div>

          <div
              class="col-4 pl-0"
              v-if="getData.hasOwnProperty('contragent_name') == true"
          >
            <div class="form-group">
              <label>Kontragent</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.contragent_name"
                  disabled
              />
            </div>
          </div>

          <div
              class="col-4 pl-0"
              v-if="getData.seller_to_corpcard_cash !== null"
          >
            <div class="form-group">
              <label>Sotuvchi</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.seller_to_corpcard_cash"
                  disabled
              />
            </div>
          </div>

          <div class="col-4" v-if="getData.responsible !== null">
            <div class="form-group">
              <label>Masul shaxs</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.resposible_description"
                  disabled
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group" v-if="getData.corp_card_description">
              <label>Karta</label>
              <input
                  type="text"
                  v-mask="'#### #### #### ####'"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.corp_card_description.number_16_digit"
                  disabled
              />
            </div>
          </div>

          <div
              class="col-4"
              v-if="getData.hasOwnProperty('contragent_contract_number') == true"
          >
            <div class="form-group">
              <label>Kontragent shartnoma</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.contragent_contract_number"
                  disabled
              />
            </div>
          </div>
        </div>
        <div
            class="main__header mt-3"
            v-if="
            getData.expenditure_description !== undefined ||
            getData.expenditure_description !== null
          "
        >
          <div
              class="col-4 pl-0"
              v-if="
              getData.expenditure_description.main_expenditure_info !==
                undefined ||
              getData.expenditure_description.main_expenditure_info !== null
            "
          >
            <div class="form-group">
              <label>Asosiy xarajat</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="
                  getData.expenditure_description.main_expenditure_info.name
                "
                  disabled
              />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>Kategoriya</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.expenditure_description.category_info.name"
                  disabled
              />
            </div>
          </div>

          <div class="col-4 pr-0">
            <div class="form-group">
              <label>SubKategoriya</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.expenditure_description.name"
                  disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="getData.operation_type == 'IMPORT'"></div>

      <div v-if="getData.operation_type == 'TRANSFER'"></div>
    </div>

    <div v-if="getData.placement_type == 'WH'">
      <div v-if="getData.operation_type == 'IMPORT'">
        <div class="main__header" style="flex-wrap: wrap">
          <div class="col-4 pl-0">
            <div class="form-group">
              <label>Registratsiya raqami</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.reg_number"
                  disabled
              />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>Sana</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.oper_date | formatDate"
                  disabled
              />
            </div>
          </div>

          <div class="col-4 pr-0">
            <div class="form-group">
              <label>Qayerga</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  value="Skladda"
                  disabled
              />
            </div>
          </div>

          <div
              class="col-4 pl-0"
              v-if="getData.hasOwnProperty('contragent_name') == true"
          >
            <div class="form-group">
              <label>Kontragent</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.contragent_name"
                  disabled
              />
            </div>
          </div>
          <div
              class="col-4 pl-0"
              v-if="getData.seller_to_corpcard_cash !== null"
          >
            <div class="form-group">
              <label>Sotuvchi</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.seller_to_corpcard_cash"
                  disabled
              />
            </div>
          </div>

          <div class="col-4" v-if="getData.responsible !== null">
            <div class="form-group">
              <label>Masul shaxs</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.resposible_description"
                  disabled
              />
            </div>
          </div>
          <div
              class="col-4"
              v-if="getData.hasOwnProperty('contragent_contract_number') == true"
          >
            <div class="form-group">
              <label>Kontragent shartnoma</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.contragent_contract_number"
                  disabled
              />
            </div>
          </div>

          <div class="col-12 row">
            <div
                class="col-12 row"
                v-if="getData.hasOwnProperty('corp_card_description') == true"
            >
              <div
                  class="col-4 pl-0 form-group"
                  v-if="getData.corp_card_description"
              >
                <div>
                  <label>Karta</label>
                  <input
                      type="text"
                      v-mask="'#### #### #### ####'"
                      class="form-control form-control-solid form-control-lg"
                      :value="getData.corp_card_description.number_16_digit"
                      disabled
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Sklad</label>
                  <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      :value="getData.placement_warehouse_name"
                      disabled
                  />
                </div>
              </div>
              <div
                  class="col-4 px-0 form-group"
                  v-if="getData.truck_details !== null"
              >
                <label>Mashina turi</label>
                <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    :value="
                    getData.truck_details.truck_type.brand.name +
                    ' ' +
                    getData.truck_details.truck_type.capacity_kg +
                    ' kg ' +
                    getData.truck_details.truck_type.capacity_m3 +
                    ' m3' +
                    getData.truck_details.reg_number_region +
                    getData.truck_details.reg_number
                  "
                    disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="getData.operation_type == 'TRANSFER'">
        <div class="main__header" style="flex-wrap: wrap">
          <div class="col-3 pl-0">
            <div class="form-group">
              <label>Reg raqam</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.reg_number"
                  disabled
              />
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label>Sana</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.oper_date | formatDate"
                  disabled
              />
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label>Sklad dan</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.exported_warehouse_description.name"
                  disabled
              />
            </div>
          </div>
          <div class="col-3 pr-0">
            <div class="form-group">
              <label>Sklad ga</label>
              <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :value="getData.imported_warehouse_description.name"
                  disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="getData.operation_type == 'EXPORT'">
      <div class="main__header" style="flex-wrap: wrap">
        <div class="col-4 pl-0">
          <div class="form-group">
            <label>Reg raqam</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.reg_number"
                disabled
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Sana</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.oper_date | formatDate"
                disabled
            />
          </div>
        </div>

        <div class="col-4 pl-0">
          <div class="form-group">
            <label>Sklad</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.exported_warehouse_description.name"
                disabled
            />
          </div>
        </div>

        <div class="col-4 pl-0">
          <div class="form-group">
            <label>Asosiy xarajat</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="
                getData.expenditure_description.main_expenditure_info.name
              "
                disabled
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Kategoriya</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.expenditure_description.category_info.name"
                disabled
            />
          </div>
        </div>

        <div class="col-4 pr-0">
          <div class="form-group">
            <label>SubKategoriya</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.expenditure_description.name"
                disabled
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="getData.operation_type == 'REAL'">
      <div class="main__header" style="flex-wrap: wrap">
        <div class="col-4 pl-0">
          <div class="form-group">
            <label>Reg raqam</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.reg_number"
                disabled
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Sana</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.oper_date | formatDate"
                disabled
            />
          </div>
        </div>

        <div class="col-4 pr-0">
          <div class="form-group">
            <label>Sana</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.oper_date | formatDate"
                disabled
            />
          </div>
        </div>

        <div class="col-4 pl-0">
          <div class="form-group">
            <label>Sklad</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.exported_warehouse_description.name"
                disabled
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Kontragent nomi</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.contragent_name"
                disabled
            />
          </div>
        </div>

        <div class="col-4 pr-0">
          <div class="form-group">
            <label>Kontragent shartnoma</label>
            <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :value="getData.contragent_contract_number"
                disabled
            />
          </div>
        </div>
      </div>
    </div>
    <div class="border-top pt-5">
      <v-data-table
          :loading="isLoading"
          :headers="headers"
          loading-text="Malumot yuklanmoqda..."
          no-data-text="Malumot topilmadi"
          :items="productDetails"
          hide-default-footer
      ></v-data-table>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'
export default {
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Nomenklatura',
        value: 'import_category.name'
      },
      {
        text: 'Mashina',
        value: 'truck.reg_number'
      },
      {
        text: 'Turi',
        value: 'import_group.name'
      },
      {
        text: 'O’lchov',
        value: 'import_category.measurement_name'
      },
      {
        text: 'Soni',
        value: 'amount'
      },

      {
        text: 'Narxi',
        value: 'price'
      },
      // {
      //   text: 'Summa',
      //   value: 'total'
      // },
      {
        text: 'NDS',
        value: 'nds'
      },
      {
        text: 'NDS summa',
        value: 'nds_summa'
      },
      {
        text: ' Summa(NDS)',
        value: 'total_withnds_summa'
      }
    ]
  }),
  beforeCreate() {
    this.$store.dispatch('getProductCondition')
    this.$store.dispatch('getMeasurements')
    this.$store.dispatch('productDetails', this.$route.params.id)
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    productDetails() {
      const data =
          this.$store.state.requests.productDetails.storedproductservice_set

      if (data !== undefined) {
        data.forEach((element) => {
          element.price = toRoundFloat(element.price.toLocaleString('es-US'))
          element.total = toRoundFloat(element.total.toLocaleString('es-US'))
          element.nds_summa = toRoundFloat(
              element.nds_summa.toLocaleString('es-US')
          )
          element.total_withnds_summa = toRoundFloat(
              element.total_withnds_summa.toLocaleString('es-US')
          )
        })
      }
      // data.push(this.$store.state.requests.productDetails);
      return this.$store.state.requests.productDetails.storedproductservice_set
    },
    getData() {
      return this.$store.state.requests.productDetails
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'THK Malumotlari' }
      // { title: 'THK(Kassa) malumotlari' }
    ])
  }
}
</script>
<style scoped>
.main__header {
  display: flex;
  /* border: 1px solid #333; */
  /* padding: 16px; */
  /* border-radius: 3px; */
}
.main__header p {
  font-weight: bold;
  margin: 0;
}
</style>
<style>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>